// import React, { useState, useEffect } from 'react';
// import Top from './Top';
// import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
// import 'leaflet/dist/leaflet.css';
// import axios from 'axios';
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';
// import L from 'leaflet';

// const startingCoords = { lat: -34.1314468566669, lng: 18.382794242854782 }; // Updated starting map center
// const FREQUENCY_OPTIONS = [
//     { value: '2', label: '2 GHz' },
//     { value: '5', label: '5 GHz' },
//     { value: '11', label: '11 GHz' },
//     { value: '17', label: '17 GHz' },
//     { value: '24', label: '24 GHz' },
//     { value: '60', label: '60 GHz' },
//     { value: '80', label: '80 GHz' },
// ];

// const calculateDistance = (lat1, lon1, lat2, lon2) => {
//     const R = 6371000; // Radius of Earth in meters
//     const dLat = ((lat2 - lat1) * Math.PI) / 180;
//     const dLon = ((lon2 - lon1) * Math.PI) / 180;
//     const a =
//         Math.sin(dLat / 2) ** 2 +
//         Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) ** 2;
//     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//     return R * c;
// };

// const calculateFSPL = (frequency, distance) => {
//     const FSPL = 20 * Math.log10(distance) + 20 * Math.log10(frequency * 1e9) - 147.55;
//     return FSPL.toFixed(2);
// };

// const calculateRSS = (P_TX, G_TX, G_RX, FSPL) => {
//     return (P_TX + G_TX + G_RX - FSPL).toFixed(2);
// };

// const calculateCapacity = (RSS, bandwidth) => {
//     if (RSS <= -100) return 0; // Ignore extremely low RSS values for simplicity.

//     const thermalNoise = 1.38e-23 * 290 * (bandwidth * 1e6); // Thermal noise power (W)
//     const signalPower = Math.pow(10, RSS / 10) / 1e3; // Convert RSS (dBm) to power in watts
//     const SNR = signalPower / thermalNoise; // Signal-to-noise ratio (linear)

//     if (SNR <= 0) return 0; // If SNR is negative or zero, capacity is 0
//     const capacity = bandwidth * Math.log2(1 + SNR); // Shannon-Hartley capacity formula

//     return capacity.toFixed(2); // Capacity in Mbps
// };

// const parseCoordinates = (coordinates) => {
//     const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
//     return { lat, lng };
// };

// const markerIcon = new L.Icon({
//     iconUrl: './markersm.png',
//     iconSize: [25, 38],
//     iconAnchor: [12, 38],
// });

// function Point() {
//     const [transmitter, setTransmitter] = useState({ coordinates: '', power: 20, gain: 15, height: 10 });
//     const [receiver, setReceiver] = useState({ coordinates: '', power: 10, gain: 10, height: 8, sensitivity: -80 });
//     const [frequency, setFrequency] = useState(5); // Default frequency in GHz
//     const [bandwidth, setBandwidth] = useState(20); // Default bandwidth in MHz
//     const [distance, setDistance] = useState(0); // Distance between transmitter and receiver
//     const [FSPL, setFSPL] = useState(0); // Free-Space Path Loss
//     const [RSS_TxToRx, setRSS_TxToRx] = useState(0); // RSS from transmitter to receiver
//     const [RSS_RxToTx, setRSS_RxToTx] = useState(0); // RSS from receiver to transmitter
//     const [capacityTxToRx, setCapacityTxToRx] = useState(0); // Capacity Tx → Rx
//     const [capacityRxToTx, setCapacityRxToTx] = useState(0); // Capacity Rx → Tx
//     const [elevationProfile, setElevationProfile] = useState([]); // Elevation profile data

//     const fetchElevationData = async (origin, destination) => {
//         const numPoints = Math.ceil(calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng) / 35);
//         const points = Array.from({ length: numPoints + 1 }, (_, i) => ({
//             lat: origin.lat + ((destination.lat - origin.lat) * i) / numPoints,
//             lng: origin.lng + ((destination.lng - origin.lng) * i) / numPoints,
//         }));

//         try {
//             const response = await axios.post('/api/get-elevation-data', { points });
//             setElevationProfile(response.data);
//         } catch (error) {
//             console.error('Error fetching elevation data:', error);
//             setElevationProfile([]);
//         }
//     };

//     useEffect(() => {
//         if (transmitter.coordinates && receiver.coordinates) {
//             const origin = parseCoordinates(transmitter.coordinates);
//             const destination = parseCoordinates(receiver.coordinates);

//             // Calculate distance
//             const dist = calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng);
//             setDistance(dist);

//             // Calculate free-space path loss (FSPL)
//             const fspl = calculateFSPL(frequency, dist);
//             setFSPL(fspl);

//             // Calculate RSS (Transmitter → Receiver)
//             const rssTxToRx = calculateRSS(transmitter.power, transmitter.gain, receiver.gain, fspl);
//             setRSS_TxToRx(rssTxToRx);

//             // Calculate RSS (Receiver → Transmitter)
//             const rssRxToTx = calculateRSS(receiver.power, receiver.gain, transmitter.gain, fspl);
//             setRSS_RxToTx(rssRxToTx);

//             // Calculate link capacity (Mbps)
//             const capacityTx = calculateCapacity(rssTxToRx, bandwidth);
//             const capacityRx = calculateCapacity(rssRxToTx, bandwidth);

//             setCapacityTxToRx(capacityTx);
//             setCapacityRxToTx(capacityRx);

//             // Fetch elevation data
//             fetchElevationData(origin, destination);
//         }
//     }, [transmitter, receiver, frequency, bandwidth]);

//     return (
//         <div style={styles.pageContainer}>
//             <Top />
//             <div style={styles.inputContainer}>
//                 <h2>Point To Point Signal Strength Calculator</h2>
//                 <div style={styles.horizontalContainer}>
//                     <div style={styles.inputGroup}>
//                         <h3>Transmitter</h3>
//                         <label>Coordinates (lat,lng)</label>
//                         <input
//                             type="text"
//                             value={transmitter.coordinates}
//                             onChange={(e) =>
//                                 setTransmitter((prev) => ({ ...prev, coordinates: e.target.value }))
//                             }
//                             style={styles.inputBox}
//                         />
//                         <label>Power (dBm)</label>
//                         <div style={styles.sliderContainer}>
//                             <input
//                                 type="range"
//                                 min="0"
//                                 max="40"
//                                 value={transmitter.power}
//                                 onChange={(e) =>
//                                     setTransmitter((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
//                                 }
//                                 style={styles.slider}
//                             />
//                             <span style={styles.sliderValue}>{transmitter.power} dBm</span>
//                         </div>
//                         <label>Gain (dBi)</label>
//                         <input
//                             type="number"
//                             value={transmitter.gain}
//                             onChange={(e) =>
//                                 setTransmitter((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
//                             }
//                             style={styles.inputBox}
//                         />
//                         <label>Height (m)</label>
//                         <input
//                             type="number"
//                             value={transmitter.height}
//                             onChange={(e) =>
//                                 setTransmitter((prev) => ({ ...prev, height: parseFloat(e.target.value) }))
//                             }
//                             style={styles.inputBox}
//                         />
//                     </div>
//                     <div style={styles.inputGroup}>
//                         <h3>Receiver</h3>
//                         <label>Coordinates (lat,lng)</label>
//                         <input
//                             type="text"
//                             value={receiver.coordinates}
//                             onChange={(e) =>
//                                 setReceiver((prev) => ({ ...prev, coordinates: e.target.value }))
//                             }
//                             style={styles.inputBox}
//                         />
//                         <label>Power (dBm)</label>
//                         <div style={styles.sliderContainer}>
//                             <input
//                                 type="range"
//                                 min="0"
//                                 max="40"
//                                 value={receiver.power}
//                                 onChange={(e) =>
//                                     setReceiver((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
//                                 }
//                                 style={styles.slider}
//                             />
//                             <span style={styles.sliderValue}>{receiver.power} dBm</span>
//                         </div>
//                         <label>Gain (dBi)</label>
//                         <input
//                             type="number"
//                             value={receiver.gain}
//                             onChange={(e) =>
//                                 setReceiver((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
//                             }
//                             style={styles.inputBox}
//                         />
//                         <label>Height (m)</label>
//                         <input
//                             type="number"
//                             value={receiver.height}
//                             onChange={(e) =>
//                                 setReceiver((prev) => ({ ...prev, height: parseFloat(e.target.value) }))
//                             }
//                             style={styles.inputBox}
//                         />
//                     </div>
//                 </div>
//                 <div style={styles.inputGroup}>
//                     <label>Frequency (GHz)</label>
//                     <select
//                         value={frequency}
//                         onChange={(e) => setFrequency(parseFloat(e.target.value))}
//                         style={styles.inputBox}
//                     >
//                         {FREQUENCY_OPTIONS.map((option) => (
//                             <option key={option.value} value={option.value}>
//                                 {option.label}
//                             </option>
//                         ))}
//                     </select>
//                     <label>Bandwidth (MHz)</label>
//                     <input
//                         type="number"
//                         value={bandwidth}
//                         onChange={(e) => setBandwidth(parseFloat(e.target.value))}
//                         style={styles.inputBox}
//                     />
//                 </div>
//                 <div style={styles.results}>
//                     <h3>Results</h3>
//                     <p>Distance: {distance.toFixed(2)} m</p>
//                     <p>Free-Space Path Loss (FSPL): {FSPL} dB</p>
//                     <p>RSS (Transmitter → Receiver): {RSS_TxToRx} dBm</p>
//                     <p>RSS (Receiver → Transmitter): {RSS_RxToTx} dBm</p>
//                     <p>Capacity (Transmitter → Receiver): {capacityTxToRx} Mbps</p>
//                     <p>Capacity (Receiver → Transmitter): {capacityRxToTx} Mbps</p>
//                 </div>
//             </div>
//             <div style={styles.elevationAndMapContainer}>
//                 <div style={styles.elevationContainer}>
//                     <h3>Elevation Profile</h3>
//                     {elevationProfile.length > 0 ? (
//                         <Line
//                             data={{
//                                 labels: elevationProfile.map((_, i) => i),
//                                 datasets: [
//                                     {
//                                         label: 'Elevation (m)',
//                                         data: elevationProfile.map((point) => point.elevation),
//                                         borderColor: 'grey',
//                                         borderWidth: 2,
//                                         fill: false,
//                                         tension: 0.4,
//                                         pointRadius: 0,
//                                     },
//                                     {
//                                         label: 'LOS',
//                                         data: elevationProfile.map((_, index) => {
//                                             const proportion = index / (elevationProfile.length - 1);
//                                             const startHeight =
//                                                 elevationProfile[0].elevation + parseFloat(transmitter.height);
//                                             const endHeight =
//                                                 elevationProfile[elevationProfile.length - 1].elevation +
//                                                 parseFloat(receiver.height);
//                                             return startHeight + proportion * (endHeight - startHeight);
//                                         }),
//                                         borderColor: 'red',
//                                         borderWidth: 1,
//                                         fill: false,
//                                         tension: 0,
//                                         pointRadius: 0,
//                                     },
//                                 ],
//                             }}
//                             options={{
//                                 responsive: true,
//                                 maintainAspectRatio: false,
//                                 scales: {
//                                     x: { title: { display: true, text: 'Points Along Path' } },
//                                     y: { title: { display: true, text: 'Elevation (m)' } },
//                                 },
//                             }}
//                             height={300}
//                             width={600}
//                         />
//                     ) : (
//                         <p>No elevation profile available. Check inputs.</p>
//                     )}
//                 </div>
//                 <div style={styles.mapContainer}>
//                     <h3>Location Map</h3>
//                     <MapContainer
//                         center={[startingCoords.lat, startingCoords.lng]}
//                         zoom={12}
//                         scrollWheelZoom={true}
//                         style={styles.mapDimensions}
//                         attributionControl={false}
//                     >
//                         <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
//                         {transmitter.coordinates && receiver.coordinates && (
//                             <>
//                                 <Marker
//                                     position={parseCoordinates(transmitter.coordinates)}
//                                     icon={markerIcon}
//                                 />
//                                 <Marker
//                                     position={parseCoordinates(receiver.coordinates)}
//                                     icon={markerIcon}
//                                 />
//                                 <Polyline
//                                     positions={[
//                                         parseCoordinates(transmitter.coordinates),
//                                         parseCoordinates(receiver.coordinates),
//                                     ]}
//                                     color="red"
//                                     weight={2}
//                                 />
//                             </>
//                         )}
//                     </MapContainer>
//                 </div>
//             </div>
//         </div>
//     );
// }

// const styles = {
//     pageContainer: { display: 'flex', flexDirection: 'column', height: '100vh' },
//     inputContainer: { padding: '20px', background: '#f7f7f7' },
//     horizontalContainer: { display: 'flex', justifyContent: 'space-between', gap: '20px' },
//     inputGroup: { flex: 1, padding: '10px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd' },
//     inputBox: { width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' },
//     sliderContainer: { display: 'flex', alignItems: 'center', gap: '10px' },
//     slider: { flex: 1, marginBottom: '10px' },
//     sliderValue: { fontWeight: 'bold', fontSize: '1rem' },
//     results: { padding: '10px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd', marginTop: '20px' },
//     elevationAndMapContainer: { display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '20px' },
//     elevationContainer: { flex: 1, padding: '20px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd', height: '400px' },
//     mapContainer: { flex: 1, height: '400px', borderRadius: '5px', border: '1px solid #ddd' },
//     mapDimensions: { height: '100%', width: '100%' },
// };

// export default Point;
import React, { useState, useEffect } from 'react';
import Top from './Top';
import { MapContainer, TileLayer, Marker, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import L from 'leaflet';

const startingCoords = { lat: -34.1314468566669, lng: 18.382794242854782 };
const FREQUENCY_OPTIONS = [
    { value: '2', label: '2 GHz' },
    { value: '5', label: '5 GHz' },
    { value: '11', label: '11 GHz' },
    { value: '17', label: '17 GHz' },
    { value: '24', label: '24 GHz' },
    { value: '60', label: '60 GHz' },
    { value: '80', label: '80 GHz' },
];
const BANDWIDTH_OPTIONS = [
    { value: '10', label: '10 MHz' },
    { value: '20', label: '20 MHz' },
    { value: '40', label: '40 MHz' },
    { value: '80', label: '80 MHz' },
    { value: '100', label: '100 MHz' },
];

const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371000;
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const calculateFSPL = (frequency, distance) => {
    const FSPL = 20 * Math.log10(distance) + 20 * Math.log10(frequency * 1e9) - 147.55;
    return FSPL.toFixed(2);
};

const calculateRSS = (P_TX, G_TX, G_RX, FSPL, noiseFloor) => {
    const rss = P_TX + G_TX + G_RX - FSPL;
    return Math.max(rss, noiseFloor); // RSS should not be lower than the noise floor
};

const calculateCapacity = (RSS, bandwidth, noiseFloor) => {
    const thermalNoise = Math.pow(10, noiseFloor / 10) / 1e3; // Convert noise floor (dBm) to W
    const signalPower = Math.pow(10, RSS / 10) / 1e3; // Convert RSS (dBm) to W
    const SNR = signalPower / thermalNoise; // Signal-to-noise ratio (linear)
    return bandwidth * Math.log2(1 + SNR); // Shannon-Hartley capacity formula in Mbps
};

const parseCoordinates = (coordinates) => {
    const [lat, lng] = coordinates.split(',').map((coord) => parseFloat(coord.trim()));
    return { lat, lng };
};

const markerIcon = new L.Icon({
    iconUrl: './markersm.png',
    iconSize: [25, 38],
    iconAnchor: [12, 38],
});

function Point() {
    const [transmitter, setTransmitter] = useState({
        coordinates: '',
        power: 20,
        gain: 15,
        height: 10,
        noiseFloor: -100,
    });
    const [receiver, setReceiver] = useState({
        coordinates: '',
        power: 10,
        gain: 10,
        height: 8,
        sensitivity: -80,
        noiseFloor: -100,
    });

    const calculateFresnelZoneRadius = (n, frequency, d1, d2, totalDistance) => {
        const speedOfLight = 299792458; // Speed of light in m/s
        const wavelength = speedOfLight / (frequency * 1e9); // Convert GHz to Hz
        return Math.sqrt((n * wavelength * d1 * d2) / (d1 + d2)); // Fresnel Zone formula
    };
    
    const [frequency, setFrequency] = useState(5);
    const [bandwidth, setBandwidth] = useState(20);
    const [distance, setDistance] = useState(0);
    const [FSPL, setFSPL] = useState(0);
    const [RSS_TxToRx, setRSS_TxToRx] = useState(0);
    const [RSS_RxToTx, setRSS_RxToTx] = useState(0);
    const [capacityTxToRx, setCapacityTxToRx] = useState(0);
    const [capacityRxToTx, setCapacityRxToTx] = useState(0);
    const [elevationProfile, setElevationProfile] = useState([]);

    const fetchElevationData = async (origin, destination) => {
        const numPoints = Math.ceil(calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng) / 35);
        const points = Array.from({ length: numPoints + 1 }, (_, i) => ({
            lat: origin.lat + ((destination.lat - origin.lat) * i) / numPoints,
            lng: origin.lng + ((destination.lng - origin.lng) * i) / numPoints,
        }));

        try {
            const response = await axios.post('/api/get-elevation-data', { points });
            setElevationProfile(response.data);
        } catch (error) {
            console.error('Error fetching elevation data:', error);
            setElevationProfile([]);
        }
    };

    useEffect(() => {
        if (transmitter.coordinates && receiver.coordinates) {
            const origin = parseCoordinates(transmitter.coordinates);
            const destination = parseCoordinates(receiver.coordinates);

            const dist = calculateDistance(origin.lat, origin.lng, destination.lat, destination.lng);
            setDistance(dist);

            const fspl = calculateFSPL(frequency, dist);
            setFSPL(fspl);

            const rssTxToRx = calculateRSS(
                transmitter.power,
                transmitter.gain,
                receiver.gain,
                fspl,
                transmitter.noiseFloor
            );
            setRSS_TxToRx(rssTxToRx);

            const rssRxToTx = calculateRSS(
                receiver.power,
                receiver.gain,
                transmitter.gain,
                fspl,
                receiver.noiseFloor
            );
            setRSS_RxToTx(rssRxToTx);

            const capacityTx = calculateCapacity(rssTxToRx, bandwidth, transmitter.noiseFloor);
            const capacityRx = calculateCapacity(rssRxToTx, bandwidth, receiver.noiseFloor);

            setCapacityTxToRx(capacityTx.toFixed(2));
            setCapacityRxToTx(capacityRx.toFixed(2));

            fetchElevationData(origin, destination);
        }
    }, [transmitter, receiver, frequency, bandwidth]);

    return (
        <div style={styles.pageContainer}>
            <Top />
            <div style={styles.inputContainer}>
                <h2>Point To Point Signal Strength Calculator</h2>
                <div style={styles.horizontalContainer}>
                <div style={styles.inputGroup}>
    <h3>Transmitter</h3>
    <label>Coordinates (lat,lng)</label>
    <input
        type="text"
        value={transmitter.coordinates}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, coordinates: e.target.value }))
        }
        style={styles.inputBox}
    />
    <label>
        Power (dBm): <span style={styles.inlineValue}>{transmitter.power} dBm</span>
    </label>
    <input
        type="range"
        min="0"
        max="40"
        value={transmitter.power}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
        }
        style={styles.slider}
    />
    <label>Gain (dBi)</label>
    <input
        type="number"
        value={transmitter.gain}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
    <label>Noise Floor (dBm)</label>
    <input
        type="number"
        value={transmitter.noiseFloor}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, noiseFloor: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
</div>
<div style={styles.inputGroup}>
    <h3>Receiver</h3>
    <label>Coordinates (lat,lng)</label>
    <input
        type="text"
        value={receiver.coordinates}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, coordinates: e.target.value }))
        }
        style={styles.inputBox}
    />
    <label>
        Power (dBm): <span style={styles.inlineValue}>{receiver.power} dBm</span>
    </label>
    <input
        type="range"
        min="0"
        max="40"
        value={receiver.power}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
        }
        style={styles.slider}
    />
    <label>Gain (dBi)</label>
    <input
        type="number"
        value={receiver.gain}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
    <label>Noise Floor (dBm)</label>
    <input
        type="number"
        value={receiver.noiseFloor}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, noiseFloor: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
</div>

                {/* <div style={styles.inputGroup}> */}
    {/* <h3>Transmitter</h3>
    <label>
        Power (dBm): <span style={styles.inlineValue}>{transmitter.power} dBm</span>
    </label>
    <input
        type="range"
        min="0"
        max="40"
        value={transmitter.power}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
        }
        style={styles.slider}
    />
    <label>Coordinates (lat,lng)</label>
    <input
        type="text"
        value={transmitter.coordinates}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, coordinates: e.target.value }))
        }
        style={styles.inputBox}
    />
    <label>Gain (dBi)</label>
    <input
        type="number"
        value={transmitter.gain}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
    <label>Noise Floor (dBm)</label>
    <input
        type="number"
        value={transmitter.noiseFloor}
        onChange={(e) =>
            setTransmitter((prev) => ({ ...prev, noiseFloor: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
</div>
<div style={styles.inputGroup}>
    <h3>Receiver</h3>
    <label>
        Power (dBm): <span style={styles.inlineValue}>{receiver.power} dBm</span>
    </label>
    <input
        type="range"
        min="0"
        max="40"
        value={receiver.power}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
        }
        style={styles.slider}
    />
    <label>Coordinates (lat,lng)</label>
    <input
        type="text"
        value={receiver.coordinates}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, coordinates: e.target.value }))
        }
        style={styles.inputBox}
    />
    <label>Gain (dBi)</label>
    <input
        type="number"
        value={receiver.gain}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
    <label>Noise Floor (dBm)</label>
    <input
        type="number"
        value={receiver.noiseFloor}
        onChange={(e) =>
            setReceiver((prev) => ({ ...prev, noiseFloor: parseFloat(e.target.value) }))
        }
        style={styles.inputBox}
    />
</div>


                    {/* <div style={styles.inputGroup}>
                        <h3>Receiver</h3>
                        <label>Coordinates (lat,lng)</label>
                        <input
                            type="text"
                            value={receiver.coordinates}
                            onChange={(e) =>
                                setReceiver((prev) => ({ ...prev, coordinates: e.target.value }))
                            }
                            style={styles.inputBox}
                        />
                        <label>Power (dBm)</label>
                        <input
                            type="range"
                            min="0"
                            max="40"
                            value={receiver.power}
                            onChange={(e) =>
                                setReceiver((prev) => ({ ...prev, power: parseFloat(e.target.value) }))
                            }
                            style={styles.slider}
                        />
                        <label>Gain (dBi)</label>
                        <input
                            type="number"
                            value={receiver.gain}
                            onChange={(e) =>
                                setReceiver((prev) => ({ ...prev, gain: parseFloat(e.target.value) }))
                            }
                            style={styles.inputBox}
                        />
                        <label>Noise Floor (dBm)</label>
                        <input
                            type="number"
                            value={receiver.noiseFloor}
                            onChange={(e) =>
                                setReceiver((prev) => ({ ...prev, noiseFloor: parseFloat(e.target.value) }))
                            }
                            style={styles.inputBox}
                        />
                    </div> */} */}
                </div>
                <div style={styles.inputGroup}>
                    <label>Frequency (GHz)</label>
                    <select
                        value={frequency}
                        onChange={(e) => setFrequency(parseFloat(e.target.value))}
                        style={styles.inputBox}
                    >
                        {FREQUENCY_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    <label>Bandwidth (MHz)</label>
                    <select
                        value={bandwidth}
                        onChange={(e) => setBandwidth(parseFloat(e.target.value))}
                        style={styles.inputBox}
                    >
                        {BANDWIDTH_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div style={styles.results}>
                    <h3>Results</h3>
                    <div style={styles.resultsGrid}>
                        <div style={styles.resultsColumn}>
                            <p>Distance: {distance.toFixed(2)} m</p>
                            <p>Free-Space Path Loss (FSPL): {FSPL} dB</p>
                        </div>
                        <div style={styles.resultsColumn}>
                            <p>RSS (Transmitter → Receiver): {RSS_TxToRx} dBm</p>
                            <p>RSS (Receiver → Transmitter): {RSS_RxToTx} dBm</p>
                        </div>
                        <div style={styles.resultsColumn}>
                            <p>Capacity (Transmitter → Receiver): {capacityTxToRx} Mbps</p>
                            <p>Capacity (Receiver → Transmitter): {capacityRxToTx} Mbps</p>
                        </div>
                    </div>
                </div>

            </div>
            <div style={styles.elevationAndMapContainer}>
                <div style={styles.elevationContainer}>
                    <h3>Elevation Profile</h3>
                    {elevationProfile.length > 0 ? (
                        <Line
                        data={{
                            labels: elevationProfile.map((_, i) => i),
                            datasets: [
                                // Elevation Data
                                {
                                    label: 'Elevation (m)',
                                    data: elevationProfile.map((point) => point.elevation),
                                    borderColor: 'grey',
                                    borderWidth: 2,
                                    fill: true,
                                    tension: 0.4,
                                    pointRadius: 0,
                                },
                                // LOS Line
                                {
                                    label: 'LOS Line',
                                    data: elevationProfile.map((_, index) => {
                                        const proportion = index / (elevationProfile.length - 1);
                                        const startHeight =
                                            elevationProfile[0].elevation + parseFloat(transmitter.height);
                                        const endHeight =
                                            elevationProfile[elevationProfile.length - 1].elevation + parseFloat(receiver.height);
                                        return startHeight + proportion * (endHeight - startHeight);
                                    }),
                                    borderColor: 'red',
                                    borderWidth: 1,
                                    fill: false,
                                    tension: 0.4,
                                    pointRadius: 0,
                                },
                                // Fresnel Zone 1 (below LOS)
                                {
                                    label: 'Fresnel Zone 1',
                                    data: elevationProfile.map((_, index) => {
                                        const proportion = index / (elevationProfile.length - 1);
                                        const d1 = proportion * distance;
                                        const d2 = distance - d1;
                                        const losHeight =
                                            elevationProfile[0].elevation +
                                            transmitter.height +
                                            proportion *
                                                (elevationProfile[elevationProfile.length - 1].elevation +
                                                    receiver.height -
                                                    (elevationProfile[0].elevation + transmitter.height));
                                        return losHeight - calculateFresnelZoneRadius(1, frequency, d1, d2, distance);
                                    }),
                                    borderColor: 'blue',
                                    borderWidth: 1,
                                    fill: false,
                                    tension: 0.4,
                                    pointRadius: 0,
                                },
                                // Fresnel Zone 2 (below LOS)
                                {
                                    label: 'Fresnel Zone 2',
                                    data: elevationProfile.map((_, index) => {
                                        const proportion = index / (elevationProfile.length - 1);
                                        const d1 = proportion * distance;
                                        const d2 = distance - d1;
                                        const losHeight =
                                            elevationProfile[0].elevation +
                                            transmitter.height +
                                            proportion *
                                                (elevationProfile[elevationProfile.length - 1].elevation +
                                                    receiver.height -
                                                    (elevationProfile[0].elevation + transmitter.height));
                                        return losHeight - calculateFresnelZoneRadius(2, frequency, d1, d2, distance);
                                    }),
                                    borderColor: 'green',
                                    borderWidth: 1,
                                    fill: false,
                                    tension: 0.4,
                                    pointRadius: 0,
                                },
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: { title: { display: true, text: 'Points Along Path' } },
                                y: { title: { display: true, text: 'Elevation (m)' } },
                            },
                        }}
                        height={300}
                        width={600}
                    />
                    
                    
                    
                    ) : (
                        <p>No elevation profile available. Check inputs.</p>
                    )}
                </div>
                <div style={styles.mapContainer}>
                    <h3>Location Map</h3>
                    <MapContainer
                        center={[startingCoords.lat, startingCoords.lng]}
                        zoom={12}
                        scrollWheelZoom={true}
                        style={styles.mapDimensions}
                        attributionControl={false}
                    >
                        <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                        {transmitter.coordinates && receiver.coordinates && (
                            <>
                                <Marker
                                    position={parseCoordinates(transmitter.coordinates)}
                                    icon={markerIcon}
                                />
                                <Marker
                                    position={parseCoordinates(receiver.coordinates)}
                                    icon={markerIcon}
                                />
                                <Polyline
                                    positions={[
                                        parseCoordinates(transmitter.coordinates),
                                        parseCoordinates(receiver.coordinates),
                                    ]}
                                    color="red"
                                    weight={2}
                                />
                            </>
                        )}
                    </MapContainer>
                </div>
            </div>
        </div>
    );
}

const styles = {
    pageContainer: { display: 'flex', flexDirection: 'column', height: '100vh', padding: '25px', borderRadius: '8px' },
    inputContainer: { padding: '20px', background: '#f7f7f7' },
    horizontalContainer: { display: 'flex', justifyContent: 'space-between', gap: '20px' },
    inputGroup: { flex: 1, padding: '10px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd' },
    inputBox: { width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' },
    slider: { width: '100%', marginBottom: '10px' },
    results: { padding: '10px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd', marginTop: '20px' },
    elevationAndMapContainer: { display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: '20px' },
    elevationContainer: { flex: 1, padding: '20px', background: '#fff', borderRadius: '5px', border: '1px solid #ddd', height: '400px' },
    mapContainer: { flex: 1, height: '400px', borderRadius: '5px', border: '1px solid #ddd' },
    mapDimensions: { height: '100%', width: '100%' },
    resultsGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
        marginTop: '10px',
    },
    resultsColumn: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '10px',
        background: '#fff',
        borderRadius: '5px',
        border: '1px solid #ddd',
        textAlign: 'center',
    },
    
};

export default Point;
